import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import styles from './Home.module.css';  
import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import ScrollCounter from "./Count"

const Home: React.FC = () => {

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', 
      });
    };
    const scrollTimeout = setTimeout(scrollToTop, 100);

    const calculateCarouselHeight = () => {
      const boxPrestations = document.querySelector(`.${styles.wrapperPres}`) as HTMLElement | null;
      if (boxPrestations) {
        const carouselItems = boxPrestations.querySelectorAll(`.${styles.carousel__item}`);
        const itemSpacing = 20;
        let totalHeight = 0;
        carouselItems.forEach(item => {
          const rect = item.getBoundingClientRect();
          totalHeight += rect.height + itemSpacing;
        });
        totalHeight -= itemSpacing;
        boxPrestations.style.height = `${totalHeight*3.5/5}px`;
        console.log(boxPrestations.style.height)
      } else {
        console.error("L'élément .carouselPres n'a pas été trouvé dans le DOM.");
      }
    };

    calculateCarouselHeight();

    return () => clearTimeout(scrollTimeout); 
  }, []);


  let settings = {
    dots: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1 ,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  if (window.matchMedia("(max-width: 480px)").matches) {
    settings = {
      ...settings, // Conserver les paramètres par défaut
      slidesToShow: 3, // Réduire le nombre de slides à afficher
    };
  }
  const telechargerFichierPDF = () => {
    const lienDeTelechargement = document.createElement('a');
    lienDeTelechargement.href = '/plaque.pdf'; 
    lienDeTelechargement.download = 'plaquette AMJE Lille.pdf';

    document.body.appendChild(lienDeTelechargement);
    lienDeTelechargement.click();
    document.body.removeChild(lienDeTelechargement);
  };

  const emojis = [
    ["./conception.svg", "Conception", "./cao.webp"],
    ["./accompagnement.svg", "Accompagnement", "./entreprise.webp"],
    ["./informatique.svg", "Informatique", "./info.webp"],
    ["./fabrication.svg", "Fabrication", "./usinage.webp"],
    ["./rse.svg", "RSE", "./rse.webp"]
  ];

  return (
    <>
      <div className={styles.content}>
        <Header />
        <div className={styles.backgroundImage}>
          <div className={styles.overlayText}>
            <div className={styles.Text}>
              <h1>La Junior de l'Industrie du Nord</h1>
              <h2>Faites passer votre entreprise au niveau supérieur grâce à AMJE Lille.</h2>
            </div>
            <div className={styles.contactButtonContainer}>
              <RouterLink to="/devis-gratuit">
                <button className={styles.contactButton}>Demander un devis gratuit</button>
              </RouterLink>
              <button className={styles.contactButton2} onClick={telechargerFichierPDF}>
                Obtenir notre plaquette 
              </button>
            </div>
          </div>
        </div>
        <div className={styles.subtitlesContainer}>
          <div className={styles.subtitles}>
            <h1>
              <span>A</span>
              <span>M</span>
              <span>J</span>
              <span>E </span>
                {" Lille"}
            </h1>
            <h2>Finaliste prix BNP Paribas du <span>Meilleur Espoir 2023</span></h2>
          </div>
        </div>
        <div className={styles.Figures}>
          <div className={styles.boxFigure}>
            <h1><ScrollCounter start={10} end={95} suffix={" %"}/></h1>
            <h2>Satisfaction client</h2>
          </div>
          <div className={styles.boxFigure}>
            <h1><ScrollCounter start={10} end={3500} prefix={"+ "}/></h1>
            <h2>Étudiants mobilisables</h2>
          </div>
          <div className={styles.boxFigure}>
            <h1><ScrollCounter start={10} end={100} prefix={"+ "}/></h1>
            <h2>Machines à disposition</h2>
          </div>
        </div>
        <div className={styles.TitlesWeb}><h1>Nos Prestations</h1></div>
        <div className={styles.boxPrestations} >
        <div className={styles.TextPres}>
            <h1>Notre offre de prestations, à l'image des <span>Arts & Métiers</span></h1>
            <h2>Avec une expertise qui n'est plus à prouver, notre approche reposant sur la qualité des enseignements des Arts & Métiers vous garantit une prestation d'une qualité exceptionnelle. </h2>
            <h2>Les machines et logiciels à notre disposition nous permettent de réaliser vos projets les plus spécifiques.</h2>
            <div className={styles.contactButtonContainer_Text}>
              <RouterLink to="/devis-gratuit">
                <button className={styles.contactButton_Text}>Demander un devis gratuit</button>
              </RouterLink>
            </div>
          </div>
          <div className={styles.wrapperPres}>
            <div className={styles.carouselPres}>
              {emojis.map((i, index) => (
                <div className={styles.carousel__item} key={index} >
                  <div className={styles.carousel__item_head}><img src={i[0]} alt="Logal" /></div>
                  <div className={styles.carousel__item_body} style={{ backgroundImage: `url(${i[2]})` }}>
                    <p className={styles.TitlePres}>{i[1]}</p>
                    {/* <img src={i[2]} alt="Img" /> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.TitlesWeb}><h1>Notre démarche</h1></div>
        <div className={styles.demarche}>
          {[0, 1, 2, 3].map((index) => (
            <div
              key={index}
              className={styles.demarchebox}
            >
              <div className={styles.demarchehead}>
                {index === 0 && <img src="anasvg.svg" alt="Analyse" />}
                {index === 1 && <img src="recrusvg.svg" alt="Recrutement" />}
                {index === 2 && <img src="suivisvg.svg" alt="Suiviregulier" />}
                {index === 3 && <img src="livrablesvg.svg" alt="RemiseLivrable" />}
              </div>
              <h1>
                {index === 0 && 'Analyse de votre projet'}
                {index === 1 && 'Recrutement des intervenants les plus qualifiés'}
                {index === 2 && 'Suivis réguliers du déroulé de l\'étude'}
                {index === 3 && 'Remise des livrables'}
              </h1>
              <div className={styles.demarcheline}></div>
              <h2>
                {index === 0 && 'Nos Chargés d’affaires étudient attentivement votre demande et la redirige vers le pôle Qualité, qui s’assure que votre projet soit compatible avec nos domaines d’expertises et que nous possédons les outils adaptés pour réaliser l’étude de manière optimale.'}
                {index === 1 && 'Afin de répondre au mieux à votre projet, nos Chargés d’Affaires recrutent et sélectionnent les profils les plus qualifiés parmi les étudiants de l’école d’ingénieur des Arts et Métiers. '}
                {index === 2 && 'Tout au long de l’étude, l’intervenant sera suivi et accompagné par un chargé d’affaire qualifié. Par ailleurs, vous serez régulièrement tenu informé de l’avancée du projet afin de vous permettre d’avoir une bonne visibilité sur le travail effectué.'}
                {index === 3 && 'Nos livrables sont remis à la date convenue par nos intervenants, qui s’engagent tout au long de l’étude à produire un travail sérieux pour émettre un livrable de qualité. Un suivi par les membres du pôle Qualité est de nouveau effectué pour s’assurer de la bonne réalisation de l’étude.  '}
              </h2> 
            </div>
          ))}
        </div>
        <div className={styles.TitlesWeb}><h1>Notre équipe</h1></div>
        <div className={styles.OurTeam}>
          <div className={styles.Teambox}>

            <div className={styles.TeamTitle}>
              <h1><span>P</span>aul <span>L</span>urin</h1>
            </div>
            <div className={styles.TeamPicture}>
              <img src="./pres.webp" alt="Pres" onClick={() => window.open('https://www.linkedin.com/in/paul-lurin/', '_blank')} ></img>
            </div>
            <div className={styles.TeamName}>
              <h1><span>P</span>résident</h1>
            </div>
          </div>

          <div className={styles.Teambox}>
            <div className={styles.TeamTitle}>
              <h1><span>R</span>aphaëlle de <span>L</span>adoucette</h1>
            </div>
            <div className={styles.TeamPicture}>
              <img src="./vicepres.webp" alt="VicePres" onClick={() => window.open('https://www.linkedin.com/in/raphaelle-de-ladoucette/', '_blank')}></img>
            </div>
            <div className={styles.TeamName}>
              <h1><span>V</span>ice-<span>P</span>résidente</h1>
            </div>
          </div>

          <div className={styles.Teambox}>
            <div className={styles.TeamTitle}>
              <h1><span>J</span>eanne <span>M</span>oragues</h1>
            </div>
            <div className={styles.TeamPicture}>
              <img src="./treso.webp" alt="Treso" onClick={() => window.open('https://www.linkedin.com/in/jeanne-moragues-94658b242/', '_blank')}></img>
            </div>
            <div className={styles.TeamName}>
              <h1><span>T</span>résorière</h1>
            </div>
          </div>

          <div className={styles.Teambox}>
            <div className={styles.TeamTitle}>
              <h1><span>P</span>auline <span>B</span>ourgoin</h1>
            </div>
            <div className={styles.TeamPicture}>
              <img src="./secgen.webp" alt="Secrets" onClick={() => window.open('https://www.linkedin.com/in/pauline-bourgoin-64a457298/', '_blank')}></img>
            </div>
            <div className={styles.TeamName}>
              <h1><span>S</span>ecrétaire <span>G</span>énérale</h1>
            </div>
          </div>
        </div>
        <div className={styles.TitlesWeb}><h1>Ils nous font confiance</h1></div>
        <div className={styles.brandsCarousel}>
          <Slider {...settings}>
            <div className= {styles.carouselBox}>
              <div className={styles.imgbody}>
                <img src="/01.webp" alt="LogoAmje" />
              </div>
            </div>
            <div className= {styles.carouselBox}>
              <div className={styles.imgbody}>
                <img src="/02.webp" alt="LogoAmje" />
              </div>
            </div>
            <div className= {styles.carouselBox}>
              <div className={styles.imgbody}>
                <img src="/03.webp" alt="LogoAmje" />
              </div>
            </div>
            <div className= {styles.carouselBox}>
              <div className={styles.imgbody}>
                <img src="/04.webp" alt="LogoAmje" />
              </div>
            </div>
            <div className= {styles.carouselBox}>
              <div className={styles.imgbody}>
                <img src="/05.webp" alt="LogoAmje" />
              </div>
            </div>
            <div className= {styles.carouselBox}>
              <div className={styles.imgbody}>
                <img src="/06.webp" alt="LogoAmje" />
              </div>
            </div>
          </Slider>
        </div>
    </div>

      <Footer />
      
    </>
    
  );
  
};

export default Home;
