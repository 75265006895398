import React, { useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import Header2 from '../../components/Header2/Header2';
import { Container, Accordion,MantineProvider } from '@mantine/core';
import styles from './Faq.module.css';

import '@mantine/core/styles.layer.css';
const Faq: React.FC = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', 
      });
    };

    const scrollTimeout = setTimeout(scrollToTop, 100);

    return () => clearTimeout(scrollTimeout); 
  }, []);
  return (
    <div className={styles.content}>
      <Header2 />
      <div className={styles.quesContainer}>
        <h1>Foire aux questions</h1>
        <MantineProvider>
          <Container size="sm" className={styles.Questions}>
            <Accordion variant="separated" className={styles.accordion}>
              <Accordion.Item className={styles.Question} value="1">
                <Accordion.Control><h2>Qu'est ce qu'une JE ?</h2></Accordion.Control>
                <Accordion.Panel><h3>Une JE, appelée <span>Junior Entreprise</span>, est une association à but non lucratif fédérée par la <span>CNJE</span>. <br></br>L'association a pour but d'ajouter une expérience pratique à l'enseignement théorique des futurs ingénieurs.</h3></Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={styles.Question} value="2">
                <Accordion.Control><h2>Pourquoi faire appel à nous ?</h2></Accordion.Control>
                <Accordion.Panel><h3><span>AMJE Lille</span> se situe dans l'un des campus des Arts et Metiers possédant 4 de ses laboratoires. Les étudiants sont formés sur des domaines variés et la présence de différents ateliers et de Travaux Pratiques (<span>usinage, forge, fonderie, métrologie</span>...) leur permet d'acquérir une grande expérience sur l'aspect pratique. <br></br>Le réseau ALUMNI, grâce à ses célèbres <span>Gadz'Arts</span>, est très développé aux Arts et Métiers, permettant ainsi un contact rapproché avec le monde des entreprises.</h3></Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={styles.Question} value="3">
                <Accordion.Control><h2>Qui travaille sur vos projets ?</h2></Accordion.Control>
                <Accordion.Panel><h3>Les futurs ingénieurs de l'ENSAM de Lille se portent volontaires en tant qu'<span>intervenants pour vos projets</span>. Pour cela, ils ont accès aux outils mis à disposition par le campus de Lille ainsi que l'aide des <span>enseignants</span>. <br></br> De plus, AMJE Lille possède <span>ses propres licences</span> concernant les différents logiciels souvent utiles lors des études réalisées.</h3></Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={styles.Question} value="4">
                <Accordion.Control><h2>Comment se déroule la réalisation de la mission ?</h2></Accordion.Control>
                <Accordion.Panel><h3>Après la <span>prise de rendez-vous</span> et la <span>validation</span> du client pour la mission, un membre d'AMJE Lille lance une recherche d'intervenant(s) qualifié(s) chez les étudiants des Arts. <br></br>Une fois sélectionné, s'effectueront <span>plusieurs réunions</span> entre le client et l'intervenant afin d'être en accord sur le déroulé de la mission.<br></br>Enfin, lorsque ce dernier a réalisé l'étude, il transmet le <span>livrable</span> au client pour une vérification. </h3></Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={styles.Question} value="5">
                <Accordion.Control><h2>Quels sont les outils et technologies couramment utilisés pour les projets à AMJE Lille ?</h2></Accordion.Control>
                <Accordion.Panel><h3>AMJE Lille dispose de l'accès aux outils du campus de Lille : <span>tours, fraiseuses, perceuses, plieuses, ateliers de fonderie, de matériaux, de métrologie</span>... <br></br>Concernant les différences <span>licences informatiques </span>communes, AMJE Lille les a acquises afin d'être le plus flexible en fonction de la demande du client.</h3></Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={styles.Question} value="6">
                <Accordion.Control><h2>Qu'est ce qui représente AMJE Lille ?</h2></Accordion.Control>
                <Accordion.Panel><h3>Nous somme une association axée sur la vie étudiante, dont beaucoup sont impliqués dans des <span>projets ambitieux</span>. Ces différents projets, uniques à notre école, permettent à tous les futurs ingénieurs Gadz'Arts d'<span>approfondir leurs connaissances dans les métiers de l'industrie</span> (conception, fabrication...). <br></br>Le côté <span>méthodique et rigoureux</span> requis lors d'une étude est également très développé dans la formation des étudiants.</h3></Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={styles.Question} value="7">
                <Accordion.Control><h2>Qu'est ce que la CNJE ?</h2></Accordion.Control>
                <Accordion.Panel><h3>La <span>Confédération Nationale des Junior-Entreprises</span> est une association fédérant les Junior-Entreprises françaises. Son objectif est de <span>favoriser l'insertion professionnelle</span> des étudiants en complétant leur enseignement théorique grâce à la mise en place d'un contact avec le monde de l'entreprise. Elle est aussi organisatrice des Congrès Nationaux, lors desquels sont proposées des <span>formations</span>.</h3></Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={styles.Question} value="8">
                <Accordion.Control><h2>Comment nous developpons-nous ?</h2></Accordion.Control>
                <Accordion.Panel><h3>De nombreuses formations sont proposées par nos partenaires <span>ALTEN et PwC</span> pour chaque pôle, permettant de <span>développer les compétences et l'expérience</span> des membres de l'AMJE Lille. <br></br>Ces formations se présentent sous forme de discussions, de présentations, ou bien d'ateliers proposant des simulations.</h3></Accordion.Panel>
              </Accordion.Item>

            </Accordion>
          </Container>
        </MantineProvider>
      </div>


      

  <Footer />
    </div>
  );
}

export default Faq;
