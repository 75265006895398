import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import styles from './Header2.module.css';

const Header2: React.FC = () => {
  const [scrolling, setScrolling] = useState(false);
  const location = useLocation(); // Utilisez useLocation pour obtenir l'URL actuelle

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolling(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const headerClass = scrolling ? styles.headerDark : styles.headerTransparent;

  return (
    <div className={`${styles.header} ${headerClass}`}>
      <RouterLink to="/" className={styles.logoContainer}>
        <img src="./../../../amje2.webp" alt="LogoAmje" />
      </RouterLink>
      <div className={styles.linksContainer}>
        <RouterLink to="/" className={location.pathname === '/' ? styles.activeLink : ''}>
          Accueil
        </RouterLink>
        {/* <RouterLink to="/nos-prestations" className={location.pathname === '/nos-prestations' ? styles.activeLink : ''}>
          Nos prestations
        </RouterLink>
        <RouterLink to="/notre-demarche" className={location.pathname === '/notre-demarche' ? styles.activeLink : ''}>
          Notre démarche
        </RouterLink>
        <RouterLink to="/notre-equipe" className={location.pathname === '/notre-equipe' ? styles.activeLink : ''}>
          Notre équipe
        </RouterLink> */}
        <RouterLink to="/foire-aux-questions" className={`${location.pathname === '/foire-aux-questions' ? styles.activeLink : ''} ${styles.link}`}>
          FAQ
        </RouterLink>
      </div>
      <RouterLink to="/contact" className={styles.contactButton}>
        <img src="./../../../call.webp" alt="phone alt"/> Nous contacter
      </RouterLink>
    </div>
  );
};

export default Header2;
