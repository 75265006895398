// Devis.tsx
import React, { useRef, useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import Footer from '../../components/Footer/Footer';
import Header2 from '../../components/Header2/Header2';
import styles from './Devis.module.css';
// import { Link as RouterLink } from 'react-router-dom';

const Devis: React.FC = () => {
  const form = useRef<HTMLFormElement | null>(null);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

    const scrollTimeout = setTimeout(scrollToTop, 100);

    return () => clearTimeout(scrollTimeout);
  }, []);

  
  const sendEmail = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (form.current) {
      try {
        const response = await emailjs.sendForm(
          'service_2qw3qvl',
          'template_3rps5js',
          form.current,
          'JnD5_sJtpK0jB2gZa'
        );
  
        console.log('Réponse de EmailJS:', response);
  
        form.current.reset();
        setFormSubmitted(true);
  
      } catch (error) {
        console.error('Erreur lors de l\'envoi de l\'e-mail:', error);
      }
    }
  };

  return (
    <div className={styles.content}>
      <Header2 />
      <div className={styles.formContainer}>
        <h1>Demande de Devis</h1>
        <h2>
          Nous vous répondrons dans les plus brefs délais. <br />
          Plus de détails dans notre {' '}
          <a href="./plaque.pdf" target="_blank" download="Plaquette AMJE Lille.pdf">Plaquette</a>

          .
        </h2>
        <form ref={form} className={styles.contactForm} onSubmit={sendEmail}>
          <div className={styles.formRow}>
            <div className={styles.formField}>
              <label htmlFor="firstName">Nom*</label>
              <input type="text" id="lastName" name="firstName" required />
            </div>
            <div className={styles.formField}>
              <label htmlFor="lastName">Prénom*</label>
              <input type="text" id="your-surname" name="lastName" required />
            </div>
          </div>
          <div className={styles.formField}>
            <label htmlFor="company">Entreprise*</label>
            <input type="text" id="company" name="company" required />
          </div>
          <div className={styles.formRow}>
            <div className={styles.formField}>
              <label htmlFor="email">Adresse e-mail*</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className={styles.formField}>
              <label htmlFor="phoneNumber">Numéro de téléphone*</label>
              <input type="tel" id="phoneNumber" name="phoneNumber" required />
            </div>
          </div>
          
          <div className={styles.formRow}>
            <div className={styles.formField}>
              <label htmlFor="subject">Titre du Devis*</label>
              <input type="text" id="subject" name="message_subject" required />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formField}>
              <label htmlFor="message">Description du devis demandé*</label>
              <textarea
                id="message"
                name="message"
                rows={15}
                minLength={30}
                style={{ resize: 'vertical' }}
                required
              ></textarea>
            </div>
          </div>

          <div className={`${styles.formField} ${styles.buttonContainer}`}>
            <button type="submit">Envoyer</button>
          </div>

          {formSubmitted && (
            <p className={styles.confirmationMessage}>
              Formulaire envoyé ! Vous serez contacté dans les plus brefs délais.
            </p>
          )}
        </form>
      </div>
      {}
      <Footer />
    </div>
  );
};

export default Devis;




// eslint-disable-next-line no-lone-blocks
{/* <div className={styles.formRow}>
<div className={styles.formField}>
<label htmlFor="activitySector">Secteur d'activité*</label>
<select
id="activitySector"
name="activitySector"
onChange={handleActivityChange}
required
>
<option value="" hidden>
Choisissez votre secteur d'activité
</option>
<option value="Bâtiment">Bâtiment</option>
<option value="Industrie">Industrie</option>
<option value="Informatique">Informatique</option>
<option value="Télécommunications">Télécommunications</option>
<option value="Transports">Transports</option>
<option value="Autre">Autre</option>
</select>
{showOtherActivity && (
<input
type="text"
id="activitySector"
name="activitySector"
placeholder="Précisez le secteur d'activité"
required
className={classNames({
  [styles.transitionEnter]: true,
  [styles.transitionEnterActive]: showOtherActivity,
  [styles.transitionExit]: !showOtherActivity,
  [styles.transitionExitActive]: !showOtherActivity,
})}
/>
)}
</div>
<div className={styles.formField}>
<label htmlFor="requiredSkills">Compétences requises*</label>
<select
id="requiredSkills"
name="requiredSkills"
onChange={handleSkillsChange}
required
>
<option value="" hidden>
Choisissez une compétence requise
</option>
<option value="Conception">Conception</option>
<option value="Fabrication">Fabrication</option>
<option value="Informatique">Informatique</option>
<option value="Autre">Autre</option>
</select>
{showOtherSkills && (
<input
type="text"
id="requiredSkills"
name="requiredSkills"
placeholder="Précisez la compétence requise"
required
className={classNames({
  [styles.transitionEnter]: true,
  [styles.transitionEnterActive]: showOtherSkills,
  [styles.transitionExit]: !showOtherSkills,
  [styles.transitionExitActive]: !showOtherSkills,
})}
/>
)}
</div>
</div> */}