import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import Devis from './pages/Devis/Devis';
import Demarche from './pages/Demarche/Demarche';
import Prestations from './pages/Prestations/Prestations';
import Equipe from './pages/Equipe/Equipe';
import Faq from './pages/Faq/Faq';
import Cgu from './pages/Cgu/Cgu';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/contact",
    element: <Contact />
  },
  {
    path: "/devis-gratuit",
    element: <Devis />
  },
  {
    path: "/notre-demarche",
    element: <Demarche />
  },
  {
    path: "/notre-equipe",
    element: <Equipe />
  },
  {
    path: "/nos-prestations",
    element: <Prestations />
  },
  {
    path: "/foire-aux-questions",
    element: <Faq />
  },
  {
    path: "/cgu",
    element: <Cgu />
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
