import React, { useState, useEffect, useRef } from 'react';

const ScrollCounter = ({ start = 10, end = 95, prefix = "", suffix = "" }) => {
  const [count, setCount] = useState(0);
  const [started, setStarted] = useState(false);
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !started) {
            let duration = 2000; // Durée totale de l'animation en millisecondes
            let startTime = performance.now();

            const updateCounter = (currentTime: number) => {
              const elapsed = currentTime - startTime;
              const progress = Math.min(1, elapsed / duration); // Limiter le progrès à 1

              // Utiliser une fonction quadratique pour ralentir davantage à la fin
              const easedProgress = 1 - Math.pow(1 - progress, 2);

              const currentValue = start + easedProgress * (end - start);
              setCount(Math.floor(currentValue));

              if (progress < 1) {
                requestAnimationFrame(updateCounter);
              } else {
                setStarted(true); // Met à jour l'état pour indiquer que le compteur a été démarré
              }
            };

            requestAnimationFrame(updateCounter);
          }
        });
      },
      { threshold: 0.5 } // Réglez le seuil selon vos préférences
    );

    // Commencez à observer le composant
    if (counterRef.current) {
      observer.observe(counterRef.current as Element);
    }

    // Nettoyez l'observateur lors du démontage du composant
    return () => {
      if (counterRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(counterRef.current as Element);
      }
    };
  }, [started, start, end]);

  return (
    <div ref={counterRef}>
      <h1>{prefix} {count} {suffix}</h1>
    </div>
  );
};

export default ScrollCounter;
