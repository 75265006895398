import React from 'react';
import Footer from '../../components/Footer/Footer';
import Header2 from '../../components/Header2/Header2';
import styles from './Equipe.module.css';

const Equipe: React.FC = () => {
  
  return (
    <div className={styles.content}>
      <Header2 />
      <div className={styles.majsite}>
        <h1>Cette page est encore en construction, revenez bientôt !</h1>
      </div>
      <Footer />
    </div>
  );
};

export default Equipe;
