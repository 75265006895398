import React, { useRef, useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import Footer from '../../components/Footer/Footer';
import Header2 from '../../components/Header2/Header2';
import styles from './Contact.module.css';
import { Link as RouterLink } from 'react-router-dom';

const Contact: React.FC = () => {
  const form = useRef<HTMLFormElement | null>(null);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', 
      });
    };

    const scrollTimeout = setTimeout(scrollToTop, 100);

    return () => clearTimeout(scrollTimeout); 
  }, []);

  const sendEmail = async (e: React.FormEvent) => {
    e.preventDefault();

    if (form.current) {
      try {
        const response = await emailjs.sendForm(
          'service_2qw3qvl',
          'template_z5tazgt',
          form.current,
          'JnD5_sJtpK0jB2gZa'
        );

        console.log('Réponse de EmailJS:', response);

        form.current.reset();

        setFormSubmitted(true);
      } catch (error) {
        console.error('Erreur lors de l\'envoi de l\'e-mail:', error);
      }
    }
  };

  return (
    <div className={styles.content}>
      <Header2 />
      <div className={styles.formContainer}>
        <h1>Contactez-nous !</h1>
        <h2>
          Une question ? Une requête ? Un projet ? C'est ici ! <br />
          Pour effectuer une demande de devis gratuite, c'est{' '}
          <RouterLink to="/devis-gratuit">
            <span>ici</span>
          </RouterLink>
          .
        </h2>
        <form ref={form} className={styles.contactForm} onSubmit={sendEmail}>
          <div className={styles.formRow}>
            <div className={styles.formField}>
              <label htmlFor="firstName">Nom*</label>
              <input type="text" id="lastName" name="firstName" required />
            </div>
            <div className={styles.formField}>
              <label htmlFor="lastName">Prénom*</label>
              <input type="text" id="your-surname" name="lastName" required />
            </div>
          </div>
          <div className={styles.formField}>
            <label htmlFor="company">Entreprise*</label>
            <input type="text" id="company" name="company" required />
          </div>
          <div className={styles.formRow}>
            <div className={styles.formField}>
              <label htmlFor="email">Adresse e-mail*</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className={styles.formField}>
              <label htmlFor="phoneNumber">Numéro de téléphone*</label>
              <input type="tel" id="phoneNumber" name="phoneNumber" required />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formField}>
              <label htmlFor="subject">Sujet du message*</label>
              <input type="text" id="subject" name="message_subject" required />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formField}>
              <label htmlFor="message">Message*</label>
              <textarea
                id="message"
                name="message"
                rows={15}
                minLength={30}
                style={{ resize: 'vertical' }} 
                required
              ></textarea>
            </div>
          </div>
          <div className={`${styles.formField} ${styles.buttonContainer}`}>
            <button type="submit">Envoyer</button>
          </div>

          {formSubmitted && (
            <p className={styles.confirmationMessage}>
              Formulaire envoyé ! Vous serez contacté dans les plus brefs délais.
            </p>
          )}
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
