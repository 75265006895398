import styles from "./Footer.module.css";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { Link as RouterLink } from 'react-router-dom';
import React from 'react'
import { FaLinkedin, FaInstagram } from 'react-icons/fa'
const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const telechargerFichierPDF = () => {
    const lienDeTelechargement = document.createElement('a');
    lienDeTelechargement.href = '/plaque.pdf'; 
    lienDeTelechargement.download = 'plaquette AMJE Lille.pdf';

    document.body.appendChild(lienDeTelechargement);
    lienDeTelechargement.click();
    document.body.removeChild(lienDeTelechargement);
  };
  useEffect(() => {
    const backButton = document.getElementById("back-to-top");

    if (backButton) {
      backButton.addEventListener("click", scrollToTop);
    }

    return () => {
      if (backButton) {
        backButton.removeEventListener("click", scrollToTop);
      }
    };
  }, []);

  return (
<div className={styles.footer}>
      {/* Première ligne */}
      <div className={styles.Backtop}>
        <span id="back-to-top" onClick={scrollToTop}>
          Retour en haut
          <FontAwesomeIcon className={styles.iconBackTop} icon={faAngleUp} onClick={scrollToTop} />
        </span>
      </div>
      <div className={styles.Infos}>
        <p>8 Boulevard Louis XIV - 59000 Lille <br></br> + 33 (0)7 83 65 78 44</p>
      </div>
      <div className={styles.contactButtonContainer}>
        <div>
          <RouterLink to="/devis-gratuit">
            <button className={styles.contactButton}>Demander un devis gratuit</button>
          </RouterLink>
        </div>
        <div>
          <button className={`${styles.contactButton} ${styles.lastButton}`} onClick={telechargerFichierPDF}>
            Obtenir notre plaquette
          </button>
        </div>
        <div>
          <RouterLink to="/cgu">
            <button className={styles.contactButton}>CGU & Mentions Légales</button>
          </RouterLink>
        </div>
      </div>
      <div className={styles.Icons}>
        <a href="https://www.instagram.com/amje_lille/" target="_blank" rel="noopener noreferrer">
          <FaInstagram className={styles.icon} />
        </a>
        <a href="https://www.linkedin.com/company/amje-lille/about/" target="_blank" rel="noopener noreferrer">
          <FaLinkedin className={styles.icon} />
        </a>
      </div>
      <div className={styles.Copyright}>
        <p>© 2024 AMJE Lille. Tous droits réservés</p>
      </div>
      <div className={styles.footerImg}>
  <div className={`${styles.cnjeImage}`}>
  <img
      src="./cnje.webp"
      alt="CNJE"
      onClick={() => window.open('https://junior-entreprises.com/', '_blank')}
    />
  </div>
  <div className={`${styles.partnersImage}`}>
    <img src="./partners.png" alt="Partners" />
  </div>

  {/* Reste de votre contenu du footer */}
</div>

    </div>
  );
};

export default Footer;
