import React, { useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import Header2 from '../../components/Header2/Header2';
import styles from './Cgu.module.css';

import '@mantine/core/styles.layer.css';
const Faq: React.FC = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', 
      });
    };

    const scrollTimeout = setTimeout(scrollToTop, 100);

    return () => clearTimeout(scrollTimeout); 
  }, []);
  return (
    <div className={styles.content}>
      <Header2 />
      <div className={styles.quesContainer}>
        <h1>Conditions Générales d'Utilisation</h1>
        <h2>1. Préambule</h2>
        <p>Les présentes Conditions Générales d'Utilisation (CGU) ont pour objet de définir les modalités et conditions dans lesquelles les utilisateurs peuvent accéder et utiliser le site https://amje-lille.fr (ci-après dénommé "le Site"). En accédant au Site, l'utilisateur accepte sans réserve les présentes CGU.</p>
        <h2>2. Accès au Site</h2>
        <p>Le Site est accessible gratuitement à tout utilisateur disposant d'un accès à Internet. Tous les coûts relatifs à l'accès au Site, qu'il s'agisse de frais matériels, logiciels ou d'accès à Internet, sont exclusivement à la charge de l'utilisateur. Le Site ne nécessite pas de création de compte ni d'inscription pour consulter son contenu.        </p>
        <h2>3. Propriété intellectuelle</h2>
        <p>Le Site est la propriété exclusive de AMJE Lille. Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du Site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de AMJE Lille.        </p>
        <h2>4. Absence de collecte de données</h2>
        <p>Le Site ne collecte aucune donnée personnelle des utilisateurs. Aucune information n'est enregistrée, analysée ou transmise à des tiers. Aucun cookie n'est utilisé par le Site.</p>
        <h2>5. Limitation de responsabilité</h2>
        <p>AMJE Lille s'efforce de fournir des informations aussi précises que possible sur le Site. Toutefois, elle ne pourra être tenue responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu'elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations. L'utilisateur utilise les informations fournies sur le Site sous sa propre responsabilité.        </p>
        <h2>6. Liens hypertextes</h2>
        <p>Le Site peut contenir des liens hypertextes vers d'autres sites. AMJE Lille n'exerce aucun contrôle sur ces sites et décline toute responsabilité quant à leur contenu. L'accès à ces sites se fait aux risques et périls de l'utilisateur.        </p>
        <h2>7. Droit applicable et juridiction compétente</h2>
        <p>Les présentes CGU sont soumises au droit français. En cas de litige relatif à l'utilisation du Site, les tribunaux français seront seuls compétents.       </p>
        <h2>8. Modification des CGU</h2>
        <p>AMJE Lille se réserve le droit de modifier à tout moment les présentes CGU. Les utilisateurs sont invités à les consulter régulièrement pour prendre connaissance des modifications éventuelles.       </p>

        <h1>Mentions Légales</h1>
        <h2>1. Éditeur du site</h2>
        <p>Le site https://amje-lille.fr est édité par l'association Arts et Métiers Junior Études ENSAM Lille, association loi 1901, déclarée à la préfecture du Nord sous le numéro W595027035, dont le siège social est situé 8 Boulevard Louis XIV, 59800 Lille, France.</p>
        <h2>2. Directeur de la publication</h2>
        <p>Le directeur de la publication est M. Paul LURIN, président de l'association Arts et Métiers Junior Études ENSAM Lille (AMJE Lille).</p>
        <h2>3. Hébergement</h2>
        <p>Le site est hébergé par Netlify, Société à capitaux privés, immatriculée sous la juridiction du Delaware (USA) sous le numéro 5472023, dont le siège social est situé à 44 MONTGOMERY STREET SUITE 300, SAN FRANCISCO, 94104, CA, United States.</p>
        <h2>4. Contact</h2>
        <p>Pour toute question, information ou réclamation concernant le Site, vous pouvez contacter l'association AMJE Lille à l'adresse suivante : communication.lille@amje.fr ou par courrier postal à l'adresse suivante : 8 Boulevard Louis XIV, 59800 Lille, France.</p>

      </div>


      

  <Footer />
    </div>
  );
}

export default Faq;
